import * as React from "react"

import Layout from "../../components/layout"
import Navbar from "../../components/navbar"
import Seo from "../../components/seo"
import productor from "../../images/productor.svg"
import reportes from "../../images/reportes.svg"
import reportes2 from "../../images/descarga-reportes.svg"
import {navigate} from "gatsby";
import {useEffect, useState} from "react";

function DashboardPage() {
    const [auth, setAuth] = useState(null);
    useEffect(() => {
        setAuth(localStorage.getItem('auth'));
    }, []);

    let authUser = null;
    let user = null;

    if (auth) {
        authUser = JSON.parse(auth);
        const {user: authUsr} = authUser;
        user = authUsr;
    }

    const goToURL = (url) => {
        navigate(url);
    }

    return(
        <Layout>
            <Navbar />
            <div className="dashInfo">
                <div className="titleGeneral h-1/4">
                    <div className="font-bold text-white text-center pt-8 pb-12">
                        Bienvenid@ <br /> {user?.first_name} {user?.last_name}
                    </div>
                </div>
                <div className="bg-white pt-6 px-6 overflow-x-auto h-3/4 rounded-t-3xl -mt-8">
                    <button className="card mb-4 bg-yellow-300" onClick={() => goToURL('add-productor')}>
                        <img src={productor} alt="productor" width={100} height={100} className="mr-4" />
                        <div className="font-bold font-greenup-titles text-green-gu text-center">
                            Dar de alta un productor
                        </div>
                    </button>
                    <button className="card mb-4 bg-green-light-gu" onClick={() => goToURL('add-evaluacion')}>
                        <img src={reportes} alt="reportes" width={100} height={100} className="mr-4" />
                        <div className="font-bold font-greenup-titles text-green-gu text-center">
                            Realizar evaluación
                        </div>
                    </button>
                    <button className="card mb-4 bg-green-light-gu" onClick={() => goToURL('reportes')}>
                        <img src={reportes2} alt="reportes" width={100} height={100} className="mr-4" />
                        <div className="font-bold font-greenup-titles text-green-gu text-center">
                            Descargar reportes
                        </div>
                    </button>
                </div>
            </div>
         
         
        </Layout>
    )
}

export const Head = () => <Seo title="Dashboard" />

export default DashboardPage
